/**
 * External dependencies.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { sanitize } from '../../utils/functions';
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import Layout from '../../components/layout';
import Content from '../../components/content';
import LogoGallery from '../../components/home/logo-gallery';
import SEO from "../../components/seo";
import ReactHubspotForm from '../../components/react-hubspot-form';
import { getOgImage } from "./../../utils/functions";
import ContactForm from '@blocks/contact-form';

import './style.scss';

/**
 * Case Study Single Post.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */
const CaseStudySingle = (props) => {
	const [submitted, setSubmitted] = useState(false);

	const {
		pageContext,
		pageContext: {
			title,
			uri,
			ourPartnersMeta: {
				ourPartners,
				headline,
			},
			casestudyPostMeta: {
				caseStudy: {
					formHeadline,
					formId,
					formPortalid,
					thankYouMessage,
					downloadLabel,
					downloadLink
				},
			},
			page: {
				frontPageMeta: { sectionSix }
			},
			seo
		}
	} = props;

	const getTagName = () => {
		const slug = uri.split('/');
		return slug[2] + '_SUBMIT';
	}

	getTagName();


	const containerClass = classnames('large-4 medium-12 small-12 columns single-cpt__form-wrap', {
		'form-submitted': submitted,
	});

	const openGraphImage = getOgImage(seo);

	return (
		<Layout title={title} uri={uri} className="single-cpt single-case-study">
			<SEO
				title={title}
				seoData={seo}
				uri={uri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>

			<h1 className="single-cpt__title" dangerouslySetInnerHTML={{ __html: sanitize(title) }} />

			<div className="row align-justify">
				<div className="large-7 medium-12 small-12 columns">
					<Content page={pageContext} />
				</div>

				<div className={containerClass}>
					{
						!submitted ? <h2 dangerouslySetInnerHTML={{ __html: sanitize(formHeadline) }} /> : null
					}

					{!isEmpty(formPortalid) && !isEmpty(formId) ? (
						<>
							<ReactHubspotForm
								portalId={formPortalid}
								formId={formId}
								setSubmitted={setSubmitted}
								tagName={getTagName()}
								tagParent="CASE STUDIES"
							/>

							{
								submitted ? (
									<div className="thankyou-message">
										<div dangerouslySetInnerHTML={{ __html: sanitize(thankYouMessage) }} />
										<a className="single-cpt__download-media" href={downloadLink} rel="noopener noreferrer" target="_blank" download>{downloadLabel}</a>
									</div>
								) : null
							}
						</>
					) : null}
				</div>
			</div>

			{
				ourPartners ? (
					<>
						<h3 className="single-cpt__headline" dangerouslySetInnerHTML={{ __html: sanitize(headline) }} />
						<LogoGallery meta={sectionSix} />
					</>
				) : null
			}
			<ContactForm />
		</Layout>
	)
};

CaseStudySingle.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default CaseStudySingle;
